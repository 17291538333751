import Vuex from 'vuex';
import Vue from 'vue';
import login from './modules/login';
import marchee from './modules/marchee'
import organisme from './modules/organisme'
import users from './modules/users'
Vue.use(Vuex)
export default new Vuex.Store({
    modules:{
        login,
        marchee,
        organisme,
        users,
    }
})