<template>
  <v-container fluid>
    <v-flex xs12>
      <v-card class="grey lighten-4 elevation-0">
        <v-card-title class="title">
          <!-- {{title}}-->
          <v-spacer></v-spacer>
          <v-btn elevation="4" fab small dark class="grey mr-2" @click.native="cancel()">
            <v-icon dark="">mdi-close-circle-outline</v-icon>
          </v-btn>
          &nbsp;
          <v-btn elevation="4" fab small dark class="blue" @click.native="save()">
            <v-icon>mdi-content-save-all</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid grid-list-sm>
            <v-layout row wrap>
              <v-flex md3 sm12> </v-flex>
              <v-flex md6 sm12>
                <v-container fluid grid-list-sm>
                  <v-layout row wrap>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="title"
                        label="Titre"
                        hint="Le titre est requis"
                        class="input-group--focused"
                        v-model="oneMarchee.title"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="title"
                        label="Titre en arabe"
                        hint="Le titre est requis"
                        class="input-group--focused"
                        v-model="oneMarchee.arabicTitle"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="journalParution"
                        label="Journal de parution"
                        class="input-group--focused"
                        v-model="oneMarchee.journalParution"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-text-field name="anep" label="ANEP" class="input-group--focused" v-model="oneMarchee.anep"></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="lieuRetraitCDC"
                        label="Lieu retrait CDC"
                        class="input-group--focused"
                        v-model="oneMarchee.lieuRetraitCDC"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="montantCDC"
                        label="montant CDC"
                        class="input-group--focused"
                        v-model="oneMarchee.montantCDC"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md4 xs12>
                      <v-menu
                        lazy
                        :close-on-content-click="true"
                        transition="v-scale-transition"
                        offset-y
                        full-width
                        :nudge-left="40"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            lazy
                            v-on="on"
                            label="Date de parution"
                            prepend-icon="mdi-calendar"
                            v-model="oneMarchee.dateParution"
                          ></v-text-field>
                        </template>
                        <v-date-picker no-title scrollable v-model="oneMarchee.dateParution"> </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex md4 xs12>
                      <v-menu
                        lazy
                        :close-on-content-click="true"
                        transition="v-scale-transition"
                        offset-y
                        full-width
                        :nudge-left="40"
                        max-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            lazy
                            v-on="on"
                            label="date d'écheance"
                            prepend-icon="mdi-calendar"
                            v-model="oneMarchee.dateEcheance"
                          ></v-text-field>
                        </template>
                        <v-date-picker no-title scrollable v-model="oneMarchee.dateEcheance"> </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-select
                        :items="wilayas"
                        item-text="name"
                        item-value="id"
                        label="Wilaya"
                        v-model="oneMarchee.wilayaMarcheeId"
                      ></v-select>
                    </v-flex>

                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <!--
                      <v-select
                        :items="allOrganismes"
                        item-text="organismName"
                        item-value="id"
                        label="organisme"
                        v-model="oneMarchee.organismeId"
                      ></v-select>
                      -->
                      <v-autocomplete
                        v-model="oneMarchee.organismeId"
                        :items="items"
                        :loading="isLoading"
                        :search-input.sync="search"
                        color="white"
                        hide-no-data
                        hide-selected
                        item-text="organismName"
                        item-value="id"
                        label="Organismes"
                        placeholder="Start typing to Search"
                        prepend-icon="mdi-database-search"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-select
                        :items="secteurs"
                        label="Secteur"
                        item-text="name"
                        item-value="id"
                        chips
                        multiple
                        v-model="oneMarchee.secteurIds"
                      ></v-select>
                    </v-flex>
                    <!--
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-select
                        :items="resultats"
                        label="Resultat"
                        item-text="name"
                        item-value="id"
                        v-model="oneMarchee.resultId"
                      ></v-select>
                    </v-flex>
                    -->
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-select
                        :items="types"
                        label="Types"
                        item-text="name"
                        item-value="id"
                        v-model="oneMarchee.tendersTypeAttachmentId"
                      ></v-select>
                    </v-flex>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Pick your logo here"
                        prepend-icon="mdi-camera"
                        label="Images"
                        small-chips
                        truncate-length="25"
                        :multiple="true"
                        @change="getTheLogo"
                      >
                      </v-file-input>
                    </v-flex>
<v-flex md12 sm12 xs12 class="mx-1 px-0">
<v-sheet
    class="mx-auto"
    elevation="8"
    max-width="800"
  >
    <v-slide-group
      v-model="selectedImage"
      class="pa-4"
      show-arrows
      color="red"
    >
      <v-slide-item
        v-for="(image, index) in getImages"
        :key="index"
        v-slot="{ active, toggle }"
      >
        <v-card
          :color="active ? 'primary' : 'grey lighten-1'"
          class="ma-4"
          height="400"
          width="400"
          @click="toggle"
        >
          <v-img
            :src="buildImageUrl(image)"
            class="grey lighten-2"
              aspect-ratio="1"
              max-width="600px"
              contain
          >
          </v-img>
          <v-btn
            absolute
            right
            top
            class="ma-2"
            @click="remove(index)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card>
      </v-slide-item>
    </v-slide-group>

    <v-expand-transition>
      <v-sheet
        v-if="selectedImage != null"
        height="100"
        tile
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <h3 class="text-h6">
             {{ getImages[selectedImage].filename }}
          </h3>
        </v-row>
      </v-sheet>
    </v-expand-transition>
  </v-sheet>
  </v-flex>
<!--
  <v-row>
      <v-col
        v-for="(image, index) in getImages"
        :key="image.id"
        cols="4"
      >
        <v-img
          :src="buildImageUrl(image)"
          aspect-ratio="1"
        />
        <v-btn @click="remove(index)">Delete</v-btn>
      </v-col>
    </v-row>
-->

                  </v-layout>
                </v-container>
              </v-flex>
              <v-flex md3 sm12> </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-snackbar :right="true" :color="color" v-model="bool">
      {{ notice }}
      <v-btn dark text @click.native="closeSnackbar">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import wilaya from '../Wilaya';
import SECTORS from '../Sectors';
import { mapGetters, mapActions } from 'vuex';
import { bus } from '../main';
import { BASE_URL } from '../Constants';

export default {
  data() {
    return {
      wilayas: wilaya,
      secteurs: SECTORS,
      types: [
        { id: 1, name: "Appel d'offre" },
        { id: 2, name: "Consultation"},
        { id: 3, name: "Attribution"},
        { id: 4, name: "Concours"},
        { id: 5, name: "Annulation"},
        { id: 6, name: "Prorogation de délai" },
        { id: 7, name: 'Infructuosité'},
        { id: 8, name: 'Mise en demeur/Résiliation' },

      ],
      resultats: [
        { id: null, name: '-:-' },
        { id: 1, name: 'Prorogation de délais' },
        { id: 2, name: "Avis d'attribution" },
        { id: 3, name: "Avis d'annulation" },
        { id: 4, name: "Avis d'infructiosité" },
        { id: 5, name: 'Mise en demeure' },
        { id: 6, name: 'Présélection' },
      ],
      selectedType: null,
      title: '',
      bool: false,
      color: '',
      notice: '',
      logo: {},
      dataUploading: [],
      imageChanged: false,
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      loadedOrganisme: false,
      selectedImage: null,

      
      
    };
  },
  created() {
        if (this.$route.params.id) {
      let ar = { id: this.$route.params.id, token: this.getToken };
      this.getMarcheeById(ar).then(e=> {
        console.log(e);
        this.entries.push(this.oneMarchee.organisme)
        this.fetchImages(ar)
      })
    }
    this.dataUploading = new FormData();
    let ar1 = { token: this.getToken };
    this.fetchAllOrganismes(ar1);

    bus.$on('successAdd', e => {
      console.log('ikechmed ar emit ayini n add');
      this.setSnackbar(e);
    });
    bus.$on('errorAdd', e => {
      console.log('ikechmed ar emit ayini n error add');
      this.setSnackbar(e);
    });

    bus.$on('successEdit', e => {
      console.log('ikechmed ar emit ayini n success edit');
      this.setSnackbar(e);
    });

    bus.$on('errorEdit', e => {
      console.log('ikechmed ar emit ayini n error edit');
      this.setSnackbar(e);
    });

  },
  computed: {
    ...mapGetters(['allOrganismes', 'getToken', 'oneMarchee', 'getImages']),
    items() {
      return this.entries.map(entry => {
        const Description =
          entry.organismName.length > this.descriptionLimit ? entry.organismName.slice(0, this.descriptionLimit) + '...' : entry.organismName;
        return Object.assign({}, entry, { Description });
      });
    }
  },
  methods: {
    ...mapActions([
      'fetchAllOrganismes',
      'whoAMI',
      'setTendersTypeAttachmentId',
      'addMarchee',
      'editMarchee',
      'addImage',
      'getMarcheeById',
      'clearMarchee',
      'fetchImages',
      'addImagesToDisplay',
      'removeOneImage'
    ]),
    buildImageUrl(item) {
      return `data:${item.type};base64,${item.data}`
    },
    save() {
      this.dataUploading.delete('marchee');
      this.oneMarchee.imageIdsToKeep = this.getImages.map(item => item.id).filter(id => id);
      let marchee = JSON.stringify(this.oneMarchee);
      this.dataUploading.append('marchee', new File([marchee], 'marchee.json', { type: 'application/json' }));
      let ar = { id: this.$route.params.id, token: this.getToken, data: this.dataUploading, bool: this.imageChanged };
      if (this.$route.params.id) {
        this.editMarchee(ar);
      } else {
        this.addMarchee(ar);
      }
    },
    remove(n) {    
      this.imageChanged = true; 
      let filename = this.getImages[n].filename;
      let filenames = Array.from(this.dataUploading.entries()).map(([key, value]) => {
        console.log(key)
        return value.name
      })
      const index = filenames.indexOf(filename);
      if(index > -1) {
        filenames.splice(index, 1)
      }
      const images = Array.from(this.dataUploading.getAll('attachment'))
      images.forEach((image, i) => {
    if (image.name === filename) {
      images.splice(i, 1)
      this.dataUploading = new FormData()
      images.forEach(image => this.dataUploading.append('attachment', image))

    }
  })
      this.removeOneImage(n);    
    },
    setSnackbar(obj) {
      this.bool = obj.bool;
      this.notice = obj.notice;
      this.color = obj.color;
    },
    closeSnackbar() {
      this.bool = !this.bool;
    },
    cancel() {
      this.clearMarchee();
      this.$router.push({ name: 'Marchees' });
    },
    getTheLogo(file) {
      //this.dataUploading.delete('attachment')
      for (let i = 0; i < file.length; i++) {
        this.dataUploading.append('attachment', file[i])
        console.log(file[i])
         const reader = new FileReader();
      reader.readAsDataURL(file[i]);
      reader.onload = () => {
        let img = {data: reader.result.split(',')[1], type: file[i].type, filename: file[i].name};
        this.addImagesToDisplay(img)
      };
      }
      this.imageChanged = true;
    },
  },
  watch: {
    search(val) {
      // Items have already been loaded
      //if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      fetch(BASE_URL+'/organismes?search='+val,{
  method: "GET",
  headers: {"Authorization": this.getToken}
})
        .then(res => res.json())
        .then(res => {
          console.log(res);
          this.entries.push(...res);
          console.log(this.entries)
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style>
</style>