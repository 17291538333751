<template>
  <v-container fluid>
    <v-flex xs12>
      <v-card>
        <v-card-title>
          <span class="title"> Marchees ({{ getMarcheeTotalCount }}) </span>
          <v-spacer></v-spacer>
          <table-header-buttons :search="search" :add="add"></table-header-buttons>
        </v-card-title>
        <tabledata :expand="false" :headers="headers" :items="allMarchees" @edit="edit" @remove="remove" @notify="notify" :page.sync="page">
        </tabledata>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="this.pagesLength(getMarcheeTotalCount)"
            :total-visible="
              this.pagesLength(getMarcheeTotalCount) < 20 ? this.pagesLength(getMarcheeTotalCount) : this.pagesLength(getMarcheeTotalCount)
            "
          ></v-pagination>
        </div>
      </v-card>
    </v-flex>
    <search-panel :rightDrawer="rightDrawer" @cancelSearch="cancelSearch" @searchData="searchData">
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-text-field name="input-1-3" label="Titre ou ANEP" light v-model="getMarcheeParams.search"></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-text-field name="input-1-3" label="Journal parution" light v-model="getMarcheeParams.journal"></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-select :items="secteurs" label="Secteur" item-text="name" item-value="id" chips multiple v-model="getMarcheeParams.sectorIds"> </v-select>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-select :items="wilayas" item-text="name" item-value="id" label="Wilaya" chips multiple v-model="getMarcheeParams.wilayaIds"></v-select>
        </v-flex>
      </v-layout>
      <v-layout row>
      <v-flex xs11 offset-xs1>
                      <v-select
                        multiple
                        chips
                        :items="types"
                        label="Types"
                        item-text="name"
                        item-value="id"
                        v-model="getMarcheeParams.tenderTypeAttachmentIds"
                      ></v-select>
                    </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-menu
            lazy
            :close-on-content-click="true"
            transition="v-scale-transition"
            offset-y
            full-width
            :nudge-left="40"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field lazy v-on="on" label="Date de parution" prepend-icon="mdi-calendar"></v-text-field>
            </template>
            <v-date-picker no-title scrollable> </v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-menu
            lazy
            :close-on-content-click="true"
            transition="v-scale-transition"
            offset-y
            full-width
            :nudge-left="40"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                lazy
                v-on="on"
                label="date d'écheance"
                prepend-icon="mdi-calendar"
              ></v-text-field>
            </template>
            <v-date-picker no-title scrollable > </v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>
    </search-panel>
    <confirm-dialog :dialog="dialog" :dialogTitle="dialogTitle" :dialogText="dialogText" @onConfirm="onConfirm" @onCancel="onCancel" />
    <v-snackbar :right="true" :color="color" v-model="bool">
      {{ notice }}
      <v-btn dark text @click.native="closeSnackbar">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import TableData from '../components/TableData.vue';
import TableHeaderButtons from '../components/TableHeaderButtons.vue';
import ConfirmDialogVue from '../components/ConfirmDialog.vue';
import SearchPanel from '../components/SearchPanel.vue';
import { mapGetters, mapActions } from 'vuex';
import { bus } from '../main';
import wilaya from '../Wilaya';
import SECTORS from '../Sectors';
export default {
  name: 'Marchees',
  components: {
    tabledata: TableData,
    'table-header-buttons': TableHeaderButtons,
    'confirm-dialog': ConfirmDialogVue,
    'search-panel': SearchPanel,
  },
  data() {
    return {
      headers: [
        { text: 'id', value: 'id', sortable: true },
        { text: 'title', value: 'title', sortable: true },
        { text: 'journalParution', value: 'journalParution', sortable: true },
        { text: 'anep', value: 'anep', sortable: false },
        { text: 'lieuRetraitCDC', value: 'lieuRetraitCDC', sortable: false },
        { text: 'dateParution', value: 'dateParution', sortable: false },
        { text: 'wilaya', value: 'wilayaMarchee.name', sortable: false },
        { text: 'dateEcheance', value: 'dateEcheance', sortable: false },
        { text: 'secteurs', value: 'secteurDTOS', sortable: false },
        { text: 'organisme', value: 'organisme.organismName', sortable: false },
        { text: 'categorie', value: 'tendersTypeName', sortable: false },
        { text: 'resultat', value: 'resultName', sortable: false },
        { text: '', value: 'actionsWithNotif', sortable: false },
      ],
      types: [
        { id: 1, name: "Appel d'offre" },
        { id: 2, name: "Consultation"},
        { id: 3, name: "Attribution"},
        { id: 4, name: "Concours"},
        { id: 5, name: "Annulation"},
        { id: 6, name: "Prorogation de délai" },
        { id: 7, name: 'Infructuosité'},
        { id: 8, name: 'Mise en demeur/Résiliation' },

      ],
      bool: false,
      color: '',
      notice: '',
      itemId: '',
      dialog: false,
      dialogTitle: 'Tender delete dialog',
      dialogText: 'Do you want to delete this tender ?',
      page: 1,
      rightDrawer: false,
      wilayas: wilaya,
      secteurs: SECTORS,
    };
  },
  watch: {
    page: function () {
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllMarchees(ar);
    },
  },
  computed: {
    ...mapGetters(['allMarchees', 'getUser', 'getToken', 'getMarcheeTotalCount', 'getMarcheeParams']),
  },
  methods: {
    ...mapActions(['fetchAllMarchees', 'whoAMI', 'deleteMarchee', 'sendNotifs', 'clearMarcheeFilters']),
    add() {
      console.log('ikechmed');
      this.$router.push({ name: 'MarcheeForm' });
    },
    edit(item) {
      console.log(item);
      this.$router.push(`/marchees/${item.id}`);
    },
    remove(item) {
      this.itemId = item.id;
      this.dialog = true;
    },
    notify(item) {
      console.log(item);
      let notif = { title: item.title, secteurId: item.secteurId, organismeName: item.organisme.organismName };
      let ar = { data: notif, token: this.getToken };
      console.log(notif);
      this.sendNotifs(ar);
    },
    onConfirm() {
      let ar = { id: this.itemId, token: this.getToken };
      this.deleteMarchee(ar);
      this.dialog = false;
    },
    onCancel() {
      this.dialog = false;
      this.itemId = '';
    },
    setSnackbar(obj) {
      this.bool = obj.bool;
      this.notice = obj.notice;
      this.color = obj.color;
    },
    closeSnackbar() {
      this.bool = !this.bool;
    },
    pagesLength(n) {
      return n <= 20 ? 1 : n <= 40 ? 2 : Math.floor(n / 20) + 1;
    },
    search() {
      console.log('IKECHMED AR SEARCH');
      this.rightDrawer = !this.rightDrawer;
    },
    cancelSearch() {
      this.clearMarcheeFilters();
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllMarchees(ar);
      this.rightDrawer = false;
    },
    searchData() {
      this.rightDrawer = false;
       let ar = { token: this.getToken, page: this.page };
      this.fetchAllMarchees(ar);
    }
  },
  created() {
    this.whoAMI();
    if (this.getUser != null) {
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllMarchees(ar);
    }
    bus.$on('successDelete', e => {
      console.log('ikechmed ar emit ayini n delete');
      this.setSnackbar(e);
    });
    bus.$on('errorDelete', e => {
      console.log('ikechmed ar emit ayini n error delete');
      this.setSnackbar(e);
    });
  },
  updated() {},
};
</script>

<style>
</style>