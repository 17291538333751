<!-- Snackbar.vue -->
<template>
  <v-snackbar v-model="isVisible" :color="color" :timeout="timeout">
    <div v-if="showLoader">
    <v-progress-linear
      height="2"
      indeterminate
      color="white"
    ></v-progress-linear>
    <span>{{message}}</span>
    </div>
    <v-btn dark text @click.native="close">Close</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    message: String,
    showLoader: Boolean,
    color: String,
    close: Function,
    timeout: Number
  },

};
</script>

<style scoped>
/* Customize styles as needed */
p {
    color: aliceblue;
}
</style>
