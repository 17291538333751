var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"items-per-page":20,"page":_vm.page,"id":_vm.id,"hide-default-footer":"","show-expand":_vm.expand},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"avatar",attrs:{"small":"","max-width":"2em","src":require(("../assets/" + (item.avatar)))}})]}},{key:"item.logoCanal",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"avatar",attrs:{"large":"","width":"80px","height":"80px","src":require(("../assets/" + (item.logoCanal)))}})]}},(_vm.isAdmin)?{key:"item.activate",fn:function(ref){
var item = ref.item;
return [_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-switch',{attrs:{"flat":""},nativeOn:{"click":function($event){return _vm.$emit('activate', item)}},model:{value:(item.activated),callback:function ($$v) {_vm.$set(item, "activated", $$v)},expression:"item.activated"}})],1)]}}:null,{key:"item.secteurDTOS",fn:function(ref){
var item = ref.item;
return [_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},_vm._l((item.secteurDTOS),function(it,ind){return _c('v-chip',{key:ind,staticStyle:{"margin":"1px"}},[_vm._v(" "+_vm._s(it.name))])}),1)]}},(_vm.isAdmin)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"teal mr-2",attrs:{"elevation":"4","fab":"","small":"","dark":""},nativeOn:{"click":function($event){return _vm.$emit('edit', item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"cyan mr-2",attrs:{"elevation":"4","fab":"","small":""},nativeOn:{"click":function($event){return _vm.$emit('remove', item)}}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1)]}}:null,{key:"item.actionsWithNotif",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"teal mr-2",attrs:{"elevation":"4","fab":"","small":"","dark":""},nativeOn:{"click":function($event){return _vm.$emit('edit', item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"cyan mr-2",attrs:{"elevation":"4","fab":"","small":""},nativeOn:{"click":function($event){return _vm.$emit('remove', item)}}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1),_c('v-btn',{staticClass:"indigo darken-4",attrs:{"elevation":"4","fab":"","small":"","dark":""},nativeOn:{"click":function($event){return _vm.$emit('notify', item)}}},[_c('v-icon',[_vm._v(" mdi-bell ")])],1)]}}],null,true)},[_c('template',{slot:"no-data"},[_c('span',[_c('p',{staticClass:"pt-2 blue--text subheading"},[_c('v-icon',{staticClass:"blue--text",attrs:{"medium":""}},[_vm._v("mdi-info")]),_vm._v("Sorry, nothing to display here :(")],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }