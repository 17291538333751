<template>
 <div>
   <!-- class="deep-purple accent-4 pl-0"-->
    <v-navigation-drawer
      :v-model="drawer"
      class="blue lighten-5"
      light
      permanent
      :mini-variant.sync="mini"
      app
    >
      <perfect-scrollbar>
      <v-list nav dense class="scroll-me">
           <v-list-item class="px-2">
        <v-list-item-avatar class="ml-0 mt-0 pl-0 pt-0">
          <v-img :src="require('../assets/tenders360.png')" contain></v-img>
        </v-list-item-avatar>

        <v-list-item-title>Tenders360</v-list-item-title>

        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.path">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
     
      </v-list>
      </perfect-scrollbar>
      <template v-slot:append>
        <div class="pa-2">
          <v-list-item link
          
          @click="seDeconnecter"
          >

            <v-list-item-icon>

              <v-icon light>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="overline ">Logout</v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-navigation-drawer>
     
  </div>
</template>
<script>
import {bus} from '../main';
import {mapActions, mapGetters} from 'vuex';
  export default {
      name:'NavSideBar',
      components: { },
      computed:{
        ...mapGetters(['isAdmin', 'isSales'])
      },
    data () {
      return {
        items: [],
        mini:true,
        drawer:true,
        logo:'',
        hotelName:''
      }
    },
    created(){
      if(!this.isSales) {
        this.items.push( { title: 'Marchées', icon: 'mdi-view-dashboard',path:'/marchees' });
        this.items.push( { title: 'Organismes', icon: 'fas fa-sitemap',path:'/organismes' });

      }
      if(this.isAdmin || this.isSales) {
        this.items.push({ title: 'Utilisateurs', icon: 'mdi-account-multiple ',path:'/users' });
      }
      bus.$on('hideDrawerevent',()=>{this.hideit})
      bus.$on('setLogoProfil',(info)=>{this.logo=info.logo;
      this.hotelName=info.hotelName;
      })
    },
    methods:{
      ...mapActions(['logout']),
      hideit(){
        console.log("hideDrawer NavSideBar.vue")
        this.drawer=!this.drawer
      },
      scrollHanle(evt) {
      console.log(evt)
    },seDeconnecter(){
      this.logout();
    },
    }
  }
</script>
<style>
.ps {
  height: 100%;
  width: 100%;
}
/*
.v-navigation-drawer__content::-webkit-scrollbar{
  width: 10px;
}
.v-navigation-drawer__content::-webkit-scrollbar-track{
  border-radius: 5px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb{
  border-radius: 5px;
  background-color: #B0BEC5;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb:hover{
  border-radius: 5px;
  background-color: #607D8B;
}
.v-navigation-drawer__content{
  overflow-y: scroll;
  scrollbar-color:#B0BEC5  rgba(0, 0, 0, 0.25);
  scrollbar-width: 5px;
 
  
}
*/
</style>