import axios from "axios";
import { BASE_URL } from "../../Constants";
import { bus } from '../../main';
const state = {
    userParams: {
        name: null,
        email: null,
        number: null,
        sectorIds: null,
    },
    userParamsToReset: {
        name: null,
        email: null,
        number: null,
        sectorIds: null,
    },
    dailyRecapSentStatus: false,
    totalUserCount: 0,
    users: [],
    oneUser: {
        loginUser: '',
        passwordUser: '',
        firstName: '',
        lastName: '',
        email: '',
        userExtraDTO: {
            username: '',
            nom: '',
            prenom: '',
            numeroTel: '',
            email: '',
            typeAbonnement: '',
            debutAbonnement: '',
            finAbonnement: '',
            secteurs: ''
        }
    },
    userToUpdate: null,
    
}
const getters = {
    getAllUsers: (state) => state.users,
    oneUser: (state) => state.oneUser,
    getUserTotalCount: (state) => state.totalUserCount,
    getOneUserExtra: (state) => state.userToUpdate,
    getUserParams: (state) => state.userParams,
}

const actions = {
    async fetchAllUsers({getters, commit }, ar) {
        let params = null;
         params ={...getters.getUserParams}

        if(params.sectorIds != null) {
            params.sectorIds= params.sectorIds.join(',')
        }
        console.log("PARAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMS")
        console.log(params)
        const response = await axios.get(`${BASE_URL}/user-extras?page=${ar.page - 1}&size=5&sort=id,desc`
            , { headers: { Authorization: ar.token }, params: params });
        console.log(response.data);
        commit('setUsers', response.data);
        commit('setTotalCount', response.headers['x-total-count'])
        /*
        */
        console.log(response.data);
        
    },
    async activateUser({ commit }, ar) {
        await axios.get(`${BASE_URL}/activate?key=${ar.key}`
            , { headers: { Authorization: ar.token } }).then(res=>{
                console.log(res);
                commit('setUsers', state.users);
                bus.$emit('successActivate', { bool: true, color: 'success', notice: "Utilisateur activé avec succès." })

            }).catch(err=>{
                console.log(err);
                bus.$emit('errorActivate', { bool: true, color: 'error', notice: "Echec activation! Veuillez réessayer ulterieurement." })

            })
    },
    async sendDailyEmailRecap({commit},ar) {
        await axios.get(`${BASE_URL}/runJob?selectedDate=${ar.date}`
            , { headers: { Authorization: ar.token } }).then(res=>{
                console.log(res);
                commit('setDailyRecapSent', true)
                bus.$emit('successSend', { bool: true, color: 'success', notice: "Recap du jour envoyé avec succès" })

            }).catch(err=>{
                console.log(err);
                commit('setDailyRecapSent', false)
                bus.$emit('errorSend', { bool: true, color: 'error', notice: "Echec envoie recap du jour! Veuillez réessayer ulterieurement." })

            })
    },
    async sendDailyEmailRecap2({ commit }, ar) {
        try {
          // Show loader
          bus.$emit('pendingRecapSend', { showLoader: true, loaderMessage: 'Envoie du recap en cours...', color: 'blue' });

    
          // Make Axios request to start the batch job
          const response = await axios.get(`${BASE_URL}/runJob?selectedDate=${ar.date}`, {
            headers: { Authorization: ar.token }
          });
    
          // Check the job status periodically
          await checkJobStatus(response.data, ar);
    
          // Job completed successfully, hide loader or show success message
          bus.$emit('successRecapSend', { showLoader: true, loaderMessage: 'Recap du jour envoyé avec succès', color: 'green' });
          commit('setDailyRecapSent', true);
        } catch (error) {
          // Handle errors, hide loader, and show error message
          bus.$emit('errorRecapSend', { showLoader: true, loaderMessage: 'Echec envoie recap du jour! Veuillez réessayer ultérieurement.', color: 'red' });
          commit('setDailyRecapSent', false);
        }
      },
    async getUserExtraById({commit}, ar) {
        await axios.get(`${BASE_URL}/user-extras/${ar.id}`
        ,{ headers: { Authorization: ar.token } }
        ).then(res=> {
            console.log(res);
            commit('setUserToUpdate', res.data);
        }).catch(err=>{
            console.log(err)
        })
    },
    async deactivateUser({ commit }, ar) {
         await axios.put(`${BASE_URL}/deactivate/${ar.id}`, {}
            , { headers: { Authorization: ar.token } }).then(res=>{
                console.log(res);
                commit('setUsers', state.users);
                bus.$emit('successDeactivate', { bool: true, color: 'success', notice: "Utilisateur desactivé avec succès." })

            }).catch(err=>{
                console.log(err);
                bus.$emit('errorDeActivate', { bool: true, color: 'error', notice: "Echec desactivation! Veuillez réessayer ulterieurement." })


            })
    },
    async saveUser({commit}, ar) {
        let user = ar.user;
        let userToSave = {
            login: user.loginUser,
            password: user.passwordUser,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            userExtraDTO: {
                username: user.loginUser,
                nom: user.firstName,
                prenom: user.lastName,
                numeroTel: user.userExtraDTO.numeroTel,
                email: user.email,
                typeAbonnement: user.userExtraDTO.typeAbonnement,
                debutAbonnement: '',
                finAbonnement: '',
                secteurs: user.userExtraDTO.secteurs.join(',')
            }
        }
        await axios.post(BASE_URL+'/register', userToSave
        ,{ headers: { Authorization: ar.token } } )
        .then((res)=>{
            commit('addUser', res.data) 
            bus.$emit('successAdd', { bool: true, color: 'success', notice: "Nouveau utilisateur ajouté avec succès." })
        }).catch(e =>{
            console.log(e);
            bus.$emit('errorAdd', { bool: true, color: 'error', notice: "Echec Ajout utilisateur." })
          
        })
    },
    async updateUser({commit}, ar) {
        let user = ar.user;
        let userToUpdate =  {
            "id": user.userId,
            "login": user.username,
            "firstName": user.nom,
            "lastName": user.prenom,
            "email": user.userEmail,
            "imageUrl": "",
            "langKey": "fr",
            "userExtraDTO": {
                "id": user.id,
                "finAbonnement": user.finAbonnement,
                "numeroTel": user.numeroTel,
            },
            "sectorIds": user.secteurIds
        }

        await axios.put(BASE_URL+'/user-info', userToUpdate
        ,{ headers: { Authorization: ar.token } } )
        .then((res)=>{
            commit('addUser', res.data) 
            bus.$emit('successEdit', { bool: true, color: 'success', notice: "Nouveau utilisateur modifié avec succès." })
        }).catch(e =>{
            console.log(e);
            bus.$emit('errorEdit', { bool: true, color: 'error', notice: e.response.data.title })
          
        })
    },
    async deleteUser({ commit }, ar) {
        await axios.delete(`${BASE_URL}/admin/users/${ar.login}`
            , { headers: { Authorization: ar.token } }).then(res => {
                commit('removeUser', ar.login);
                console.log(res);
                bus.$emit('successDelete', { bool: true, color: 'success', notice: "Operation faite avec succès." })
            })
            .catch(err => {
                console.log(err);
                bus.$emit('errorDelete', { bool: true, color: 'error', notice: "Echec operation! Veuillez réessayer ulterieurement." })
            })
    },
    async clearUserFilters({commit})  {
       await commit('clearUserFilters');
    },



}

const mutations = {
    setUsers: (state, users) => state.users = users,
    setOneUser: (state, user) => state.oneUser = user,
    addUser: (state, user) => state.users.push(user),
    setTotalCount: (state, count) => state.totalUserCount = parseInt(count, 10),
    setUserToUpdate: (state, userExtra) => state.userToUpdate = userExtra,
    removeUser: (state, login) => (state.users = state.users.filter(user => user.username !== login)),
    clearUserFilters: (state) =>state.userParams = state.userParamsToReset,
    setDailyRecapSent: (state)=>state.dailyRecapSentStatus = state.status

}
async function checkJobStatus(jobId, ar) {
    let jobCompleted = false;
    while (!jobCompleted) {
      const response = await axios.get(`${BASE_URL}/jobStatus/${jobId}`, {
        headers: { Authorization: ar.token}
      });
  
      if (response.data === 'COMPLETED') {
        // Job completed, exit the loop
        jobCompleted = true;
      } else if (response.data === 'FAILED' || response.data === 'UNKNOWN') {
        // Handle failure or unknown status, exit the loop
        throw new Error(`Job status: ${response.data}`);
      }
  
      // Add a delay before checking the status again
      await new Promise(resolve => setTimeout(resolve, 2000));
    }
  }
  


export default {
    state,
    getters,
    actions,
    mutations
}