const SECTORS =[{id: 1, name: "Aéronautique"},
{id: 2, name: "Agriculture, élevage, forêt et pêche"},
{id: 3, name: "Agro-alimentaire et alimentation"},
{id: 4, name: "Ameublement et mobilier"},
{id: 5, name: "Architecture et urbanisme"},
{id: 6, name: "Assurance et banque"},
{id: 7, name: "Bâtiment et génie civil"},
{id: 8, name: "Chimie et pétrochimie"},
{id: 9, name: "Électricité bâtiment et éclairage publique"},
{id: 47, name: "Electroménager"},
{id: 10, name: "Énergie et services pétroliers"},
{id: 11, name: "Équipements industriels, outillage et pièces détachées"},
{id: 48, name: "Equipements pour les collectivités et équipements de ville"},
{id: 12, name: "Équipements scientifiques et laboratoires"},
{id: 13, name: "Études et consulting"},
{id: 14, name: "Formation et certification"},
{id: 15, name: "Froid, climatisation, chauffage et plomberie"},
{id: 16, name: "Habillement et textile"},
{id: 17, name: "Hydraulique, assainissement traitement des eaux"},
{id: 18, name: "Hygiène et environnement"},
{id: 19, name: "Immobilier"},
{id: 20, name: "Impression, édition et communication"},
{id: 21, name: "Industrie de la cellulose, papier, carton et emballage"},
{id: 22, name: "Industrie électroniques et matériel audiovisuel"},
{id: 23, name: "Industrie manufacturière"},
{id: 24, name: "Industrie sidérurgique, métallurgique et mécanique"},
{id: 25, name: "Industries électriques et électrotechnique"},
{id: 26, name: "Informatique et bureautique"},
{id: 27, name: "Maintenance des équipements industriels"},
{id: 28, name: "Matériaux de construction"},
{id: 29, name: "Médical et paramédical"},
{id: 30, name: "Mines, cimenterie, agrégats et granulats"},
{id: 31, name: "Pharmacie"},
{id: 46, name: "Pneumatiques et pièces de rechange auto"},
{id: 32, name: "Port, aéroport et transit"},
{id: 33, name: "PVC, aluminium, vitrerie et menuiserie bois"},
{id: 45, name: "Quincaillerie"},
{id: 34, name: "Restauration et hôtellerie"},
{id: 35, name: "Sablage, peinture et travaux plâtre"},
{id: 36, name: "Sécurité, gardiennage et vidéosurveillance"},
{id: 37, name: "Services"},
{id: 38, name: "Services de comptabilité et commissariat aux comptes"},
{id: 39, name: "Sport et loisirs"},
{id: 40, name: "Télécommunication"},
{id: 41, name: "Tourisme et voyage"},
{id: 42, name: "Transports"},
{id: 43, name: "Travaux publics"},
{id: 44, name: "Vente aux enchères"}];
export default SECTORS;