const wilaya =
    [
        {"id":1,"code":"1","name":"Adrar", "arabicName":"أدرار"},
        {"id":2,"code":"2","name":"Chlef", "arabicName":"الشلف"},
        {"id":3,"code":"3","name":"Laghouat", "arabicName":"الأغواط"},
        {"id":4,"code":"4","name":"Oum El Bouaghi", "arabicName":"أم البواقي"},
        {"id":5,"code":"5","name":"Batna", "arabicName":"باتنة"},
        {"id":6,"code":"6","name":"Béjaïa", "arabicName":"بجاية"},
        {"id":7,"code":"7","name":"Biskra", "arabicName":"بسكرة"},
        {"id":8,"code":"8","name":"Béchar", "arabicName":"بشار"},
        {"id":9,"code":"9","name":"Blida", "arabicName":"البليدة"},
        {"id":10,"code":"10","name":"Bouira", "arabicName":"البويرة"},
        {"id":11,"code":"11","name":"Tamanrasset", "arabicName":"تمنراست"},
        {"id":12,"code":"12","name":"Tébessa", "arabicName":"تبسة"},
        {"id":13,"code":"13","name":"Tlemcen", "arabicName":"تلمسان"},
        {"id":14,"code":"14","name":"Tiaret", "arabicName":"تيارت"},
        {"id":15,"code":"15","name":"Tizi Ouzou", "arabicName":"تيزي وزو"},
        {"id":16,"code":"16","name":"Alger", "arabicName":"الجزائر"},
        {"id":17,"code":"17","name":"Djelfa", "arabicName":"الجلفة"},
        {"id":18,"code":"18","name":"Jijel", "arabicName":"جيجل"},
        {"id":19,"code":"19","name":"Sétif", "arabicName":"سطيف"},
        {"id":20,"code":"20","name":"Saïda", "arabicName":"سعيدة"},
        {"id":21,"code":"21","name":"Skikda", "arabicName":"سكيكدة"},
        {"id":22,"code":"22","name":"Sidi Bel Abbès", "arabicName":"سيدي بلعباس"},
        {"id":23,"code":"23","name":"Annaba", "arabicName":"عنابة"},
        {"id":24,"code":"24","name":"Guelma", "arabicName":"قالمة"},
        {"id":25,"code":"25","name":"Constantine", "arabicName":"قسنطينة"},
        {"id":26,"code":"26","name":"Médéa", "arabicName":"المدية"},
        {"id":27,"code":"27","name":"Mostaganem", "arabicName":"مستغانم"},
        {"id":28,"code":"28","name":"M'Sila", "arabicName":"المسيلة"},
        {"id":29,"code":"29","name":"Mascara", "arabicName":"معسكر"},
        {"id":30,"code":"30","name":"Ouargla", "arabicName":"ورقلة"},
        {"id":31,"code":"31","name":"Oran", "arabicName":"وهران"},
        {"id":32,"code":"32","name":"El Bayadh", "arabicName":"البيض"},
        {"id":33,"code":"33","name":"Illizi", "arabicName":"إليزي"},
        {"id":34,"code":"34","name":"Bordj Bou Arreridj", "arabicName":"برج بوعريريج"},
        {"id":35,"code":"35","name":"Boumerdès", "arabicName":"بومرداس"},
        {"id":36,"code":"36","name":"El Tarf", "arabicName":"الطارف"},
        {"id":37,"code":"37","name":"Tindouf", "arabicName":"تندوف"},
        {"id":38,"code":"38","name":"Tissemsilt", "arabicName":"تيسمسيلت"},
        {"id":39,"code":"39","name":"El Oued", "arabicName":"الوادي"},
        {"id":40,"code":"40","name":"Khenchela", "arabicName":"خنشلة"},
        {"id":41,"code":"41","name":"Souk Ahras", "arabicName":"سوق أهراس"},
        {"id":42,"code":"42","name":"Tipaza", "arabicName":"تيبازة"},
        {"id":43,"code":"43","name":"Mila", "arabicName":"ميلة"},
        {"id":44,"code":"44","name":"Aïn Defla", "arabicName":"عين الدفلى"},
        {"id":45,"code":"45","name":"Naâma", "arabicName":"النعامة"},
        {"id":46,"code":"46","name":"Aïn Témouchent", "arabicName":"عين تموشنت"},
        {"id":47,"code":"47","name":"Ghardaïa", "arabicName":"غرداية"},
        {"id":48,"code":"48","name":"Relizane", "arabicName":"غليزان"},
        {"id":49,"code":"49","name":"Timimoune", "arabicName":"تيميمون"},
        {"id":50,"code":"50","name":"Bordj Badji Mokhtar", "arabicName":"برج باجي مختار"},
        {"id":51,"code":"51","name":"Ouled Djellal", "arabicName":"أولاد جلال"},
        {"id":52,"code":"52","name":"Béni Abbès", "arabicName":"بني عباس"},
        {"id":53,"code":"53","name":"In Salah", "arabicName":"عين صالح"},
        {"id":54,"code":"54","name":"In Guezzam", "arabicName":"عين قزام"},
        {"id":55,"code":"55","name":"Touggourt", "arabicName":"تقرت"},
        {"id":56,"code":"56","name":"Djanet", "arabicName":"جانت"},
        {"id":57,"code":"57","name":"El M'Ghair", "arabicName":"المغير"},
        {"id":58,"code":"58","name":"El Meniaa", "arabicName":"المنيعة"}
      
      
      ];
export default wilaya;