<template>
  <v-app>
    <template v-if="!signedIn">
      <Login />
    </template>
    <template v-else>
      <NavSideBar></NavSideBar>
      <v-app-bar app color="" dense light>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>

        <v-toolbar-title class="overline">Tenders360</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="seDeconnecter">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>

      <v-main>
        <router-view />
      </v-main>
    </template>
  </v-app>
</template>

<script>
import NavSideBar from './components/NavSideBar.vue';
import { mapActions, mapGetters } from 'vuex';
import Login from './views/Login.vue';
import { bus } from './main';
export default {
  name: 'App',
  components: {
    NavSideBar,
    Login,
  },

  data: () => ({
    lo: false,
  }),
  computed: mapGetters(['signedIn', 'getToken', 'getUser']),
  methods: {
    ...mapActions(['logout', 'whoAMI', 'auth']),
    fireHideit() {
      console.log('emitHideDrawer App.vue');
      bus.$emit('hideDrawerevent');
    },
    seDeconnecter() {
      this.logout();
    },
  },
  created() {
    this.whoAMI()
    console.log('this.signedIn');
    console.log(this.signedIn);
    let ar = { token: this.getToken };
    this.auth(ar);
    /*  if(this.signedIn){
        this.$router.push({name:'Dashboard'})
      }else{
        this.$router.push({name:'Login'})
      }
      */
  },
};
</script>
