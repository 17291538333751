<template>
    <v-container fluid>
    <v-flex xs12>
      <v-card class="grey lighten-4 elevation-0">
        <v-card-title class="title">
         <!-- {{title}}-->
          <v-spacer></v-spacer>
          <v-btn elevation="4" fab small dark class="grey mr-2" @click.native="cancel()">
            <v-icon dark="">mdi-close-circle-outline</v-icon>
          </v-btn>
          &nbsp;
          <v-btn elevation="4" fab small dark class="blue" @click.native="save()">
            <v-icon>mdi-content-save-all</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
            <v-container fluid grid-list-sm>
            <v-layout row wrap>
              <v-flex md3 sm12>
               


              </v-flex>
              <v-flex md6 sm12>
                <v-container fluid grid-list-sm>
                  <v-layout row wrap>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="organismName"
                        label="Nom organisme"
                        hint="Nom is required"
                        v-model="oneOrganisme.organismName"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="organismAdress"
                        label="adresse organisme"
                        hint="Address is required"
                        v-model="oneOrganisme.organismAdress"
                        
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="phoneNumber"
                        label="numéro de tel"
                        hint="Phone number is required"
                        v-model="oneOrganisme.phoneNumber"
                        
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="fixNumber"
                        label="numéro du fix"
                        hint="Fix number is required"
                        v-model="oneOrganisme.fixNumber"
                        
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="faxNumber"
                        label="numéro du fax"
                        hint="Fax number is required"
                        v-model="oneOrganisme.faxNumber"
                        
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-select
                        :items="wilayas"
                        item-text="name"
                        item-value="id"
                        label="Wilaya"
                        v-model="oneOrganisme.wilayaOrganismeId"
                      ></v-select>
                    </v-flex>

                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="website"
                        label="site web"
                        v-model="oneOrganisme.website"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    
                  </v-layout>
                </v-container>
              </v-flex>
              <v-flex md3 sm12>
               


              </v-flex>
            </v-layout>
            </v-container>
            
        </v-card-text>
        
      </v-card>
      
    </v-flex>
    <v-snackbar
      :right="true"
      :color="color"
      v-model="bool">
      {{ notice }}
      <v-btn dark text @click.native="closeSnackbar">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import {mapActions,mapGetters} from 'vuex'
import {bus} from '../main'
import wilaya from '../Wilaya';
export default {
   data() {
       return {
           wilayas: wilaya,
           items:['occupe','libre'],
           title:'',
           bool:false,color:'',notice:'',
           }
   },
   computed: mapGetters(['oneOrganisme','getUser','getToken']),
   methods:{
       ...mapActions(['addOrganisme','getOrganismeById','whoAMI','fetchAllOrganismes', 'editOrganisme', 'clearOrganisme']),
       cancel(){
         this.clearOrganisme();
           this.$router.push({name:'Organismes'});
           
       },
       save(){
            let ar2={id: this.$route.params.id,organisme:this.oneOrganisme,token:this.getToken}
            if(this.$route.params.id){
                this.editOrganisme(ar2);
            }else {
                this.addOrganisme(ar2);
            }
           
       },
       setSnackbar(obj){
            this.bool=obj.bool;
            this.notice=obj.notice;
            this.color=obj.color;
        },
        closeSnackbar(){
            this.bool= !this.bool;
        },     
   },
    mounted() {
      if(this.getUser!=null){
       
    if (this.$route.params.id) {
      this.title = "Modifier Client";
      // this.customerAvatar();
    } else this.title = " Nouveau Client";
      }
  },
  created(){
  
    bus.$on('successAdd',e=>{
            console.log("ikechmed ar emit ayini n add")
            this.setSnackbar(e);
        });
         bus.$on('errorAdd',e=>{
            console.log("ikechmed ar emit ayini n error add")
            this.setSnackbar(e);
        });

        bus.$on('successEdit',e=>{
            console.log("ikechmed ar emit ayini n success edit")
            this.setSnackbar(e);
        });

        bus.$on('errorEdit',e=>{
            console.log("ikechmed ar emit ayini n error edit")
            this.setSnackbar(e);
        });
      if(this.$route.params.id){
      let ar={id:this.$route.params.id, token:this.getToken}
      this.getOrganismeById(ar);
    
    }
    }
}
</script>
<style scoped>

</style>