<template>
  <div>
    <v-btn @click.native="showCalendar = true">Open Calendar</v-btn>

    <v-dialog v-model="calendarDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Select a Date
        </v-card-title>
        <v-card-text>
          <v-date-picker v-model="selectedDate" @change="submitDate"></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-btn @click.native="showCalendar">Cancel</v-btn>
          <v-btn @click.native="sendDaily">Envoyer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { bus } from '../main';
export default {
  props:{
    'calendarDialog': Boolean,
   'sendDaily': Function,
   'showCalendar': Function},
  data() {
    return {
      selectedDate: null,
      dialog: false,
    };
  },
  methods: {
    submitDate() {
    bus.$emit('selectedDate', this.selectedDate);
    },
    formatDate(date) {
        console.log(date)
      // Implement a function to format the date as needed
      // You may want to use a library like moment.js or date-fns
      // For example, using date-fns:
      // import { format } from 'date-fns';
      // return format(date, 'yyyy-MM-dd');
    },
  },
};
</script>
