<template>
  <div>
    <v-card-text>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-text>
    <v-data-table class="elevation-1" :search="search" :headers="headers" :items="items" :items-per-page="20" :page.sync="page" :id="id" hide-default-footer :show-expand="expand">
      <template v-slot:[`item.avatar`]="{ item }">
        <v-img small max-width="2em" :src="require(`../assets/${item.avatar}`)" class="avatar" />
      </template>

      <template v-slot:[`item.logoCanal`]="{ item }">
        <v-img large width="80px" height="80px" :src="require(`../assets/${item.logoCanal}`)" class="avatar" />
      </template>
    
      <template  v-if="isAdmin" v-slot:[`item.activate`]="{ item }">
        <v-container class="px-0" fluid>
          <v-switch
            v-model="item.activated"
            flat
            @click.native="$emit('activate', item)"
          ></v-switch>
        </v-container>
      </template>

     <template v-slot:[`item.secteurDTOS`]="{ item }">
        <v-container class="px-0" fluid>
          <v-chip v-for="(it,ind) in item.secteurDTOS" :key="ind" style="margin: 1px"> {{it.name}}</v-chip>
        </v-container>
      </template>

      <template  v-if="isAdmin" v-slot:[`item.actions`]="{ item }">
        <v-btn elevation="4" fab class="teal mr-2" small dark @click.native="$emit('edit', item)">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
        <v-btn elevation="4" fab class="cyan mr-2" small @click.native="$emit('remove', item)">
          <v-icon> mdi-trash-can-outline </v-icon>
        </v-btn>
      </template>

          <template v-slot:[`item.actionsWithNotif`]="{ item }">
        <v-btn elevation="4" fab class="teal mr-2" small dark @click.native="$emit('edit', item)">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
        <v-btn elevation="4" fab class="cyan mr-2" small @click.native="$emit('remove', item)">
          <v-icon> mdi-trash-can-outline </v-icon>
        </v-btn>
        <v-btn elevation="4" fab class="indigo darken-4" small dark @click.native="$emit('notify', item)">
          <v-icon> mdi-bell </v-icon>
        </v-btn>
      </template>
<!--
      <template v-slot:expanded-item="{ items, item }">
        <div>

        </div>
      </template>
-->
      <!-- <template v-slot:[`item.activate`]="{ item }">
        <v-btn elevation="4" fab class="mr-2" :color="item.activated ? 'teal' : 'error'" small dark @click.native="$emit('activate', item)">
          <v-icon> mdi-check-outline </v-icon>
        </v-btn>
      </template> -->

      <template slot="no-data">
        <span>
          <p class="pt-2 blue--text subheading"><v-icon medium class="blue--text">mdi-info</v-icon>Sorry, nothing to display here :(</p>
        </span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { bus } from '../main';
export default {
  props: ['headers', 'items', 'headersClient', 'clients', 'id', 'page', 'expand', 'isAdmin'],
  data() {
    return {
      search: '',
      rowsPerPage: 5,
    };
  },
  created() {
    bus.$on('perPageChanged', n => {
      console.log(n);
      this.changeRowsPerpage(n);
    });
  },
  methods: {
    changeRowsPerpage(n) {
      this.rowsPerPage = n;
    },
  },
};
</script>