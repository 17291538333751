import axios from "axios"
import { BASE_URL } from "../../Constants"
const state={
    logged:false,
    user:null,
    token:null,
    users:[],
    selectedUser:{id:'',login:'',password:'',idHotel:'',admin:''},
    userReset:{id:'',login:'',password:'',idHotel:'',admin:''},
}
const getters={
signedIn:(state)=>state.logged,
getUser:(state)=>state.user,
getToken:(state)=>state.token,
getUsers:(state)=>state.users,
getSelectedUser:(state)=>state.selectedUser,
isAdmin:(state)=>state.user.auth.split(',').includes('ROLE_ADMIN'),
isSales:(state)=>state.user.auth.split(',').includes('ROLE_SALES'),

}
const actions={
async login({commit},user){
 await axios.post(BASE_URL+'/authenticate',user).then(res=>{
     let token=res.headers.authorization;
     commit('setLogged',true);
     localStorage.setItem('token',res.headers.authorization);
     if (token.startsWith('Bearer ')) {
        token = token.slice(7, token.length)
        var tokens = token.split(".");
        commit('setUser',JSON.parse(atob(tokens[1])));
     }
 })
 console.log("full response");
 
},
async whoAMI({commit}){
    let tok=localStorage.getItem("token");
    if(tok && tok.startsWith('Bearer ')){
        commit('setAuthorizationHeader',tok);
        tok = tok.slice(7, tok.length)
        var toks = tok.split(".");
        commit('setUser',JSON.parse(atob(toks[1])));
        console.log("payload")
        console.log(JSON.parse(atob(toks[1])))
        commit('setLogged',true);
        
    }else{
        commit('setUser',null);
        commit('setLogged',false);
        commit('setAuthorizationHeader',null);        
    }
    
},async logout({commit}){
    localStorage.removeItem('token');
        commit('setUser',null);
        commit('setLogged',false);
        commit('setAuthorizationHeader',null);
        commit('setLogged',false);
        //router.push("/login");
},
async auth({commit}, ar) {
    let tok=localStorage.getItem("token");
    if(tok && tok.startsWith('Bearer ')) {
        await axios.get(BASE_URL+'/account',{headers:{Authorization:ar.token}})
        .then(res =>{
            console.log(res);
            commit('setLogged',true);
        })
        .catch(err => {
            console.log(err);
            commit('setLogged',false);
        })
    }
},



}
const mutations={
    setUser:(state,user)=>(state.user=user),
    setLogged:(state,bool)=>(state.logged=bool),
    setAuthorizationHeader:(state,tok)=>(state.token=tok),
    setUsers:(state,users)=>(state.users=users),
    setSelectedUser:(state,user)=>(state.selectedUser=user),
    removeUser:(state,id)=>(state.users=state.users.filter(user=>user.id!==id)),
    newUser:(state,user)=>(state.users.push(user)),
    reseThetUser:(state)=>(state.selectedUser=state.userReset),
    setUserinfos:(state,idhotel)=>(state.selectedUser.idHotel=idhotel),
    setAdmin:(state,bool)=>state.selectedUser.admin=bool,

}

export default{
    state,
    getters,
    actions,
    mutations
}