import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Marchee from '../views/Marchee.vue'
import MarcheeForm from '../views/MarcheeForm.vue'
import Organisme from '../views/Organisme.vue'
import OrganismeForm from '../views/OrganismeForm.vue'
import Utilisateur from '../views/Utilisateur.vue'
import UtilisateurForm from '../views/UtilisateurForm.vue'
import UtilisateurFormForUpdate from '../views/UtilisateurFormForUpdate.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  
  {
    path: '/marchees',
    name: 'Marchees',
    component: Marchee
  },
  {
    path: '/newMarchee',
    name: 'MarcheeForm',
    component: MarcheeForm
  },
  {
    path: '/marchees/:id',
    name: 'MarcheeForm',
    component: MarcheeForm
  },
  {
    path: '/organismes',
    name: 'Organismes',
    component: Organisme
  },
  {
    path: '/newOrganisme',
    name: 'OrganismeForm',
    component: OrganismeForm
  },
  {
    path: '/organismes/:id',
    name: 'OrganismeForm',
    component: OrganismeForm
  },
  {
    path: '/users',
    name: 'Utilisateur',
    component: Utilisateur
  },
  {
    path: '/newUtilisateur',
    name: 'UtilisateurForm',
    component: UtilisateurForm
  },
  {
    path: '/users/:id',
    name: 'UtilisateurFormForUpdate',
    component: UtilisateurFormForUpdate
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
