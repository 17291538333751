<template>
  <v-container fluid>
    <v-flex xs12>
      <v-card>
        <v-card-title>
          <span class="title">Utilisateurs ({{ getUserTotalCount }}) </span>
          <v-spacer></v-spacer>
          <table-header-buttons :isAdmin="isAdmin" :add="add" :search="search" :send="openDialogTosendDailyRecap"></table-header-buttons>
        </v-card-title>
        <tabledata 
        :headers="headers" 
        :items="getAllUsers" 
        @activate="activate" 
        :expand="true"         
        @edit="edit"
        @remove="remove"
        :isAdmin="isAdmin"
          ></tabledata>
        <div class="text-center pt-2">
        <v-pagination
        v-model="page"
        :length="this.pagesLength(getUserTotalCount)"
        :total-visible="30"
      ></v-pagination>
        </div>
      </v-card>
    </v-flex>
    <search-panel :rightDrawer="rightDrawer" @cancelSearch="cancelSearch" @searchData="searchData">
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-text-field name="input-1-3" label="Numero Tél" light v-model="getUserParams.number"></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-text-field name="input-1-3" label="Nom ou Prenom" light v-model="getUserParams.name"></v-text-field>
        </v-flex>
      </v-layout>
            <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-text-field name="input-1-3" label="Email" light v-model="getUserParams.email"></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-select :items="secteurs" label="Secteur" item-text="name" item-value="id" chips multiple v-model="getUserParams.sectorIds"> </v-select>
        </v-flex>
      </v-layout>

    </search-panel>
    <confirm-dialog :dialog="dialog" :dialogTitle="dialogTitle" :dialogText="dialogText" @onConfirm="onConfirm" @onCancel="onCancel" />
    <calendar-dialog :calendarDialog="calendarDialog" :sendDaily="sendDaily" :showCalendar="showCalendar"></calendar-dialog>
    <v-snackbar :right="true" :color="color" v-model="bool">
      {{ notice }}
      <v-btn dark text @click.native="closeSnackbar">Close</v-btn>
    </v-snackbar>
    <loading-snackbar
      :isVisible="showLoader"
      :message="loaderMessage"
      :showLoader="showLoader"
      :color="loderSnackbarColor"
      :close="closeSnackbarLoader"
      timeout="-1"
    >
    </loading-snackbar>

  </v-container>
</template>
<script>
import TableData from '../components/TableData.vue';
import ConfirmDialogVue from '../components/ConfirmDialog.vue';
import SearchPanel from '../components/SearchPanel.vue';
import { mapGetters, mapActions } from 'vuex';
import { bus } from '../main';
import SECTORS from '../Sectors';
import TableHeaderButtonsForUsersViewVue from '../components/TableHeaderButtonsForUsersView.vue';
import CalendarDialogVue from '../components/CalendarDialog.vue';
import LoadingSnackBarVue from '../components/LoadingSnackBar.vue';
export default {
  name: 'Utilisateur',
  components: { tabledata: TableData, 'table-header-buttons': TableHeaderButtonsForUsersViewVue,
   'confirm-dialog': ConfirmDialogVue, 'search-panel': SearchPanel, 'calendar-dialog': CalendarDialogVue, 'loading-snackbar': LoadingSnackBarVue},
  computed: {
    ...mapGetters(['getAllUsers', 'getUser', 'getToken', 'getUserTotalCount', 'getUserParams', 'isAdmin']),
  },
  created() {
    this.whoAMI();
    let ar = { token: this.getToken, page: this.page };
    this.fetchAllUsers(ar);
    bus.$on('successActivate', e => {
      console.log('ikechmed ar emit ayini n activate');
      this.color = e.color;
      this.bool = e.bool;
      this.notice = e.notice;
    });

    bus.$on('errorActivate', e => {
      console.log('ikechmed ar emit ayini n activate');
      this.setSnackbar(e);
    });
    bus.$on('successDeactivate', e => {
      console.log('ikechmed ar emit ayini n deactivate');
      this.color = e.color;
      this.bool = e.bool;
      this.notice = e.notice;
    });

    bus.$on('errorDeactivate', e => {
      console.log('ikechmed ar emit ayini n deactivate');
      this.setSnackbar(e);
    });

      bus.$on('successSend', e => {
      this.color = e.color;
      this.bool = e.bool;
      this.notice = e.notice;
    });

    bus.$on('errorSend', e => {
      this.setSnackbar(e);
    });
    bus.$on('pendingRecapSend', e => {
      this.showLoader = e.showLoader;
      this.loaderMessage = e.loaderMessage;
      this.loderSnackbarColor = e.color;
    });
    bus.$on('successRecapSend', e => {
      this.showLoader = e.showLoader;
      this.loaderMessage = e.loaderMessage;
      this.loderSnackbarColor = e.color;
    });

    bus.$on('errorRecapSend', e => {
      console.log("ERROR RECAP SEND")
      this.showLoader = e.showLoader;
      this.loaderMessage = e.loaderMessage;
      this.loderSnackbarColor = e.color;
    });
    bus.$on('selectedDate', date => {
      this.selectedDate=date;
    });
  },

  data() {
    return {
      headers: [
        { text: 'ID', left: true, value: 'userId' },
        { text: 'Login', value: 'username' },
        { text: 'Nom', value: 'nom' },
        { text: 'Prénom', value: 'prenom' },
        { text: 'Email', value: 'userEmail' },
        /*{ text: 'Télephone', value: 'numeroTel' },*/
        { text: 'Secteurs', value: 'secteurDTOS' },
        /*{ text: 'Type abonnement', value: 'typeAbonnement' },*/
        { text: 'Début Abonnement', value: 'debutAbonnement' },
        { text: 'Fin Abonnement', value: 'finAbonnement' },
        { text: 'Statut', value: 'activate', sortable: false },
        {text:"",value:"actions",sortable:false},
      ],
      bool: false,
      color: '',
      notice: '',
      itemId: '',
      login: '',
      dialog: false,
      dialogTitle: 'Customer delete dialog',
      dialogText: 'Do you want to delete this customer ?',
      calendarDialog: false,
      page: 1,
      rightDrawer: false,
      secteurs: SECTORS,
      selectedDate: null,
      showLoader: false,
      loaderMessage: '', // Initial message
      loderSnackbarColor: ''
    };
  },
  watch: {
    page: function(){
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllUsers(ar);
    }
  },
  methods: {
    ...mapActions(['fetchAllUsers', 'activateUser', 'whoAMI', 'deactivateUser', 'deleteUser', 'clearUserFilters', 'sendDailyEmailRecap', 'sendDailyEmailRecap2']),
    add() {
      console.log('ikechmed');
      this.$router.push('/newUtilisateur');
    },
    edit(item) {
      console.log(item);
      this.$router.push(`/users/${item.id}`);
    },
    remove(item) {
      this.login = item.username
      this.itemId = item.id;
      this.dialog = true;
    },
    activate(item) {
      console.log('activate' + item.activationKey);

      if (item.activationKey != null) {
        let ar = { key: item.activationKey, token: this.getToken };
        this.activateUser(ar).then(res => {
          console.log(res);
          let ar2 = { token: this.getToken, page: this.page};
          this.fetchAllUsers(ar2);
        });
      } else {
        let ar = { id: item.userId, token: this.getToken, page: this.page};
        this.deactivateUser(ar).then(res => {
          console.log(res);
          let ar2 = { token: this.getToken };
          this.fetchAllUsers(ar2);
        });
      }
    },
    reloadData() {
      console.log('reloadData');
    },
    closeSnackbar() {
      this.bool = !this.bool;
    },
    showCalendar(){
      this.calendarDialog=!this.calendarDialog
    },
    closeSnackbarLoader() {
      this.showLoader = !this.showLoader;
    },
    onConfirm() {
      let ar = { login: this.login, token: this.getToken };
      this.deleteUser(ar);
      this.dialog = false;
    },
    onCancel() {
      this.dialog = false;
      this.itemId = '';
    },
    setSnackbar(obj) {
      this.bool = obj.bool;
      this.notice = obj.notice;
      this.color = obj.color;
    },
    print() {},
    pagesLength(n){
      return n <= 5 ? 1 : n <= 10 ? 2 : Math.floor(n/5) + 1 
    },
    search() {
      console.log(' SEARCH');
      this.rightDrawer = !this.rightDrawer;
    },
    openDialogTosendDailyRecap() {
      console.log(' SendDailyRecap');
      this.calendarDialog= true;
      //let ar = { token: this.getToken, page: this.page };
      //this.sendDailyEmailRecap(ar);
    },
    sendDaily() {
      let ar = { token: this.getToken, date: this.selectedDate };
      this.sendDailyEmailRecap2(ar);
      this.calendarDialog= false;
    },
    cancelSearch() {
      this.clearUserFilters().then(()=>{
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllUsers(ar);
      this.rightDrawer = false;
      })

    },
    searchData() {
      this.rightDrawer = false;
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllUsers(ar);
    },
   
  },
};
</script>