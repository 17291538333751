<template>
  <v-app>
    <v-overlay dark color="blue lighten-5" opacity="1">
      <v-container fluid>
        <v-layout>
          <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
            <div>
              <v-tabs v-model="tab" show-arrows background-color="deep-purple accent-4" icons-and-text dark grow>
                <v-tabs-slider color="purple darken-4"></v-tabs-slider>
                <v-tab v-for="(i, id) in tabs" :key="id">
                  <v-icon large>{{ i.icon }}</v-icon>
                  <div class="caption py-1">{{ i.name }}</div>
                </v-tab>
                <v-tab-item>
                  <v-card class="px-4">
                    <v-card-text>
                      <v-form ref="loginForm" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="loginUsername" label="Username" required></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="loginPassword"
                              :append-icon="show1 ? 'eye' : 'eye-off'"
                              :rules="[rules.required, rules.min]"
                              :type="show1 ? 'text' : 'password'"
                              name="input-10-1"
                              label="Password"
                              hint="At least 8 characters"
                              counter
                              @click:append="show1 = !show1"
                            ></v-text-field>
                          </v-col>
                          <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
                          <v-spacer></v-spacer>
                          <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                            <v-btn x-large block :disabled="!valid" color="success" @click="validate"> Login </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </div>
          </v-dialog>
        </v-layout>
      </v-container>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
//import {bus} from '../main'
export default {
  name: 'Login',
  computed: {},
  created() {},
  methods: {
    ...mapActions(['login']),
    validate() {
      if (this.$refs.loginForm.validate()) {
        // submit form to server/API here...
        let user = { username: this.loginUsername, password: this.loginPassword };
        console.log(user);
        this.login(user);
      }
    },
  },
  data: () => ({
    dialog: true,
    tab: 0,
    tabs: [
      { name: 'Login', icon: 'mdi-account' },
      
    ],
    valid: true,
    loginPassword: '',
    loginUsername: '',

    show1: false,
    rules: {
      required: value => !!value || 'Required.',
      min: v => (v && v.length >= 4) || 'Min 4 characters',
    },
  }),
};
</script>
<style scoped>
.login-form {
  max-width: 500px;
}
</style>