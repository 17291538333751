<template>
   <v-container fluid>
        <v-flex xs12>
      <v-card>
        <v-card-title>
          <span class="title">
            Organismes ({{getOrganismeTotalCount}})
          </span>
          <v-spacer></v-spacer>
          <table-header-buttons
            :add="add"
          ></table-header-buttons>
        </v-card-title>
        <tabledata
        :expand="false"
        :headers="headers" 
        :items="allOrganismes"
        @edit="edit"
        @remove="remove"
        :page.sync="page"></tabledata>
        <div class="text-center pt-2">
        <v-pagination
        v-model="page"
        :length="this.pagesLength(getOrganismeTotalCount)"
        :total-visible="this.pagesLength(getOrganismeTotalCount) < 20 ? this.pagesLength(getOrganismeTotalCount) : this.pagesLength(getOrganismeTotalCount)"
      ></v-pagination>
        </div>
      </v-card>
    </v-flex>
    <confirm-dialog
      :dialog="dialog"
      :dialogTitle="dialogTitle"
      :dialogText="dialogText"
      @onConfirm="onConfirm"
      @onCancel="onCancel"
    />
    <v-snackbar
      
      :right="true"
      :color="color"
      v-model="bool"
      
    >
      {{ notice }}
      <v-btn dark text @click.native="closeSnackbar">Close</v-btn>
    </v-snackbar>
    </v-container>
</template>

<script>
import TableData from '../components/TableData.vue'
import TableHeaderButtons from '../components/TableHeaderButtons.vue'
import ConfirmDialogVue from '../components/ConfirmDialog.vue';
import {mapGetters,mapActions} from 'vuex';
import {bus} from '../main'
export default {
name: 'Organismes',
components: {
    'tabledata': TableData,
    'table-header-buttons':TableHeaderButtons,
    'confirm-dialog':ConfirmDialogVue
},
data() {
    return {
        headers:[
                {text:"id",value:"id",sortable:true},
                {text:"organismName",value:"organismName",sortable:true},
                {text:"organismAdress",value:"organismAdress",sortable:true},
                {text:"phoneNumber",value:"phoneNumber",sortable:false},
                {text:"fixNumber",value:"fixNumber",sortable:false},
                {text:"faxNumber",value:"faxNumber",sortable:false},
                {text:"website",value:"website",sortable:false},
                {text:"",value:"actions",sortable:false},
            ],
            bool:false,color:'',notice:'',itemId:'',dialog:false,
            dialogTitle:'Organism delete dialog',
            dialogText:'Do you want to delete this organism ?',
            page: 1,
    }
},
watch: {
    page: function(){
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllOrganismes(ar);
    }
  },
computed:mapGetters(['allOrganismes','getUser','getToken', 'getOrganismeTotalCount']),
methods:{
    ...mapActions(['fetchAllOrganismes', 'whoAMI', 'deleteOrganisme']),
     add(){
            this.$router.push({name:'OrganismeForm'});
        },
        edit(item){
            console.log(item);
            this.$router.push(`/organismes/${item.id}`)
        },
        remove(item){
            this.itemId=item.id;
            this.dialog=true;
            
        },
        onConfirm(){
           let ar={id:this.itemId,token:this.getToken}
            this.deleteOrganisme(ar);
            this.dialog=false;
        },
        onCancel(){
            this.dialog=false;
            this.itemId='';
        },
        setSnackbar(obj){
            this.bool=obj.bool;
            this.notice=obj.notice;
            this.color=obj.color;
        },
        closeSnackbar(){
            this.bool= !this.bool;
        },
    pagesLength(n){
      return n <= 20 ? 1 : n <= 40 ? 2 : Math.floor(n/20) + 1 
    }  
},
created() {
    this.whoAMI();
    if(this.getUser != null) {
        let ar = {token:this.getToken, page: this.page}
        this.fetchAllOrganismes(ar);
    }
     bus.$on('successDelete', e => {
      console.log('ikechmed ar emit ayini n delete');
      this.setSnackbar(e);
    });
    bus.$on('errorDelete', e => {
      console.log('ikechmed ar emit ayini n error delete');
      this.setSnackbar(e);
    });

}
}
</script>

<style>

</style>